<template>
  <div style="padding: 24rem ;display: flex">
    <el-button @click="onDownload" size="small" style="height: 32rem;margin-right: 24rem">下载模板</el-button>
    <el-upload
        class="upload-demo"
        :on-success="onSuccess"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :headers="{ authorization: 'Bearer ' + $store.getters.token }"
        :action="$_axios2.defaults.baseURL + '/api/sub/dorm/index'"
        name="a"
        accept=".xlsx,.xls"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data(){
    return{
    }
  },
  methods: {
    onDownload() {
      location.href = process.env.VUE_APP_URL_BASE + '/template/dormitory_student.xlsx';
    },
    onSuccess(res) {
      console.log(res)
      if (res.status === 0) this.$message.success('上传成功')
        else this.$message.error(res.msg)
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning'
        })
      }
      return extension || extension2
    }
  },
}
</script>

<style scoped>

</style>
